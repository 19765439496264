import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    ReferenceField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
} from 'react-admin';

// Affichage de la liste des membres
export const MemberList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="nom" label="Nom" />
            <TextField source="prenom" label="Prénom" />
            <TextField source="email" label="Email" />
            <TextField source="pays" label="Pays" />
            <TextField source="telephone" label="Téléphone" />
            <TextField source="adresse" label="Adresse" />
            <TextField source="profession" label="Profession" />
            <TextField source="region" label="Région" />
            <DateField source="date_naissance" label="Date de naissance" />
            <TextField source="sexe" label="Sexe" />
            <TextField source="secteur" label="Secteur" />
            {/* Nouveau champ booléen pour le statut d'abonnement */}
            <BooleanField source="subscription_status" label="Abonnement validé" />
            {/* Affichage du supérieur via une référence (si besoin) */}
            <ReferenceField source="superieur_id" reference="members" label="Supérieur">
                <TextField source="nom" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

// Edition d'un membre pour valider l'abonnement
export const MemberEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nom" disabled />
            <TextInput source="prenom" disabled />
            <TextInput source="email" disabled />
            <TextInput source="pays" disabled />
            <TextInput source="telephone" disabled />
            <TextInput source="adresse" disabled />
            <TextInput source="profession" disabled />
            <TextInput source="region" disabled />
            <TextInput source="sexe" disabled />
            <TextInput source="secteur" disabled />
            {/* Permettre à l'administrateur de valider l'abonnement */}
            <BooleanInput source="subscription_status" label="Valider" />
        </SimpleForm>
    </Edit>
);
