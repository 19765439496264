const apiUrl = process.env.REACT_APP_API_URL;

const authProvider = {
  login: async ({ email, password, google_token, type }) => {
    const endpoint = type === "google" ? "login/google" : "login";
    const method = type === "google" ? "GET" : "POST";
    const body = type === "google" ? JSON.stringify({ token: google_token }) : JSON.stringify({ email, password });

    const request = new Request(`${apiUrl}/${endpoint}`, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body,
    });

    return fetch(request)
      .then((response) => {
        console.log("Login response status:", response.status); // Log du statut de la réponse
        if (response.status < 200 || response.status >= 300) {
          return response.json().then((err) => {
            throw new Error(err.error || "Erreur de connexion");
          });
        }
        return response.json();
      })
      .then((auth) => {
        console.log("Auth object before storing:", auth); // Vérifiez ce que contient auth
        localStorage.setItem("auth", JSON.stringify(auth));
        console.log("Token stored in localStorage:", localStorage.getItem("auth")); // Vérifiez si le token est bien stocké
    });
  },

  logout: () => {
    const auth = localStorage.getItem("auth");
    console.log("Logout auth token:", auth); // Log du token pendant la déconnexion

    if (!auth) {
      localStorage.removeItem("auth");
      return Promise.resolve();
    }

    const request = new Request(`${apiUrl}/logout`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(auth).access_token}`,
      }),
    });

    return fetch(request).then(() => {
      localStorage.removeItem("auth");
      return Promise.resolve();
    });
  },

  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getIdentity: () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.user) {
      return Promise.resolve(auth.user);
    }
    return Promise.reject();
  },

  getPermissions: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const user = JSON.parse(auth).user;
      return Promise.resolve(user.role);
    }
    return Promise.reject();
  },
};

export default authProvider;
