import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput,List,Datagrid,TextField,ReferenceField, Edit } from 'react-admin';
import authProvider from './authProvider';


export const MessageAdminCreate = props => {
    const [userRole, setUserRole] = React.useState(null);
    const [loading, setLoading] = React.useState(true); // État pour indiquer le chargement

    React.useEffect(() => {
        authProvider
            .getPermissions()
            .then((permissions) => {
                setUserRole(permissions); // Supposons que `permissions` retourne directement le rôle
                console.log("Rôle de l'utilisateur :", permissions);
            })
            .catch(() => {
                console.log("Erreur lors de la récupération des permissions.");
                setUserRole(null);
            })
            .finally(() => setLoading(false)); // Fin du chargement
    }, []);

    // Afficher un état de chargement pendant la vérification du rôle
    if (loading) {
        return <div>Chargement...</div>;
    }

    // Vérifiez le rôle avant d'afficher le formulaire
    if (userRole !== 'admin') {
        return <div>Accès refusé.</div>;
    }

    // Rendre le formulaire si l'utilisateur est admin
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="titre" label="Titre" />
                <TextInput source="contenu" label="Contenu" multiline />
                <ReferenceInput source="destinataire_id" reference="members" label="Destinataire">
                    <SelectInput optionText="nom" />
                </ReferenceInput>
                <ReferenceInput source="envoye_par_id" reference="members" label="Envoyé par">
                    <SelectInput optionText="nom" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const MessageAdminEdit = props => {
    const [userRole, setUserRole] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        authProvider
            .getPermissions()
            .then((permissions) => {
                setUserRole(permissions);
                console.log("Rôle de l'utilisateur :", permissions);
            })
            .catch(() => {
                console.log("Erreur lors de la récupération des permissions.");
                setUserRole(null);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (userRole !== 'admin') {
        return <div>Accès refusé.</div>;
    }

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="titre" label="Titre" />
                <TextInput source="contenu" label="Contenu" multiline />
                <ReferenceInput source="destinataire_id" reference="members" label="Destinataire">
                    <SelectInput optionText="nom" />
                </ReferenceInput>
                <ReferenceInput source="envoye_par_id" reference="members" label="Envoyé par">
                    <SelectInput optionText="nom" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
export const MessageAdminList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="titre" label="Titre" />
            <TextField source="contenu" label="Contenu" />
            <ReferenceField source="destinataire_id" reference="membres" label="Destinataire">
                <TextField source="nom" />
            </ReferenceField>
            <ReferenceField source="envoye_par_id" reference="membres" label="Envoyé par">
                <TextField source="nom" />
            </ReferenceField>
        </Datagrid>
    </List>
);