import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { Button } from "@mui/material";
import authProvider from "./authProvider";


const apiUrl = process.env.REACT_APP_API_URL;

const CustomActions = () => {
  const record = useRecordContext(); // Récupère la ligne sélectionnée
  const notify = useNotify();
  const refresh = useRefresh();

  const handleAction = async (status) => {
    try {
      // Appel de l’API pour mettre à jour le statut
      const response = await fetch(
        `${apiUrl}/demande/${record.id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth")
            ).access_token}`,
          },
          body: JSON.stringify({ status }), // Envoi du statut en tant que booléen
        }
      );

      if (!response.ok) {
        throw new Error("Une erreur est survenue lors de la mise à jour.");
      }

      notify(`Demande ${status ? "acceptée" : "refusée"} avec succès.`);
      refresh(); // Actualise la liste
    } catch (error) {
      console.error(error);
      notify("Une erreur est survenue.", { type: "error" });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleAction(true)} // Passe `true` pour "acceptée"
      >
        Accepter
      </Button>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => handleAction(false)} // Passe `false` pour "refusée"
        style={{ marginLeft: "8px" }}
      >
        Refuser
      </Button>
    </>
  );
};


export const DemandeList = (props) => {
  const [userRole, setUserRole] = React.useState(null);

  React.useEffect(() => {
    authProvider
      .getPermissions()
      .then((permissions) => setUserRole(permissions))
      .catch(() => setUserRole(null));
  }, []);

  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="membre_id" />
        <TextField source="service_id" />
        <TextField source="objet" />
        <TextField source="corps" />
        <TextField source="status" /> {/* Utilisez TextField ici */}
        {userRole === "admin" && <CustomActions />}
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
