import { Edit, SimpleForm, SelectInput, List, Datagrid, TextField, EditButton, Create } from 'react-admin';

export const SuperieurList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="nom" label="Rôle du Supérieur" />
            <EditButton />
        </Datagrid>
    </List>
);

export const SuperieurEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput
                source="nom"
                label="Rôle du Supérieur"
                choices={[
                    { id: 'Secretaire general', name: 'Secretaire general' },
                    { id: 'Secretaire general adjoint', name: 'Secretaire general adjoint' },
                    { id: 'Délégué régional', name: 'Délégué régional' },
                ]}
            />
        </SimpleForm>
    </Edit>
);

export const SuperieurCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput
                source="nom"
                label="Rôle du Supérieur"
                choices={[
                    { id: 'Secretaire general', name: 'Secretaire general' },
                    { id: 'Secretaire general adjoint', name: 'Secretaire general adjoint' },
                    { id: 'Délégué régional', name: 'Délégué régional' },
                ]}
            />
        </SimpleForm>
    </Create>
);

