import { Admin, Resource, List, Datagrid, TextField, ReferenceField, Show, SimpleShowLayout, Create, SimpleForm, TextInput, useDataProvider, useNotify, useRefresh, useRecordContext } from "react-admin";
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Bouton pour transférer un message
const TransferButton = () => {
    const record = useRecordContext(); // Récupère les données du message
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    if (!record) return null;

    const handleTransfer = async () => {
        try {
            await dataProvider.update("transfererMessage", { id: record.id, data: {} });
            notify("Message transféré avec succès", { type: "success" });
            refresh(); // Rafraîchir la liste
        } catch (error) {
            notify("Erreur lors du transfert", { type: "error" });
        }
    };

    return <Button onClick={handleTransfer} variant="contained" color="primary">Transférer</Button>;
};

// Liste des messages
export const MessageList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="objet" />
            <TextField source="corps" />
            <ReferenceField source="envoye_par_id" reference="members" label="Expéditeur">
                <TextField source="nom" />
            </ReferenceField>
            <ReferenceField source="superieur_id" reference="members" label="Destinataire (Supérieur)">
                <TextField source="nom" />
            </ReferenceField>
            <TransferButton />
        </Datagrid>
    </List>
);

// Affichage d'un message
export const MessageShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="objet" />
            <TextField source="corps" />
            <ReferenceField source="envoye_par_id" reference="members" label="Expéditeur">
                <TextField source="nom" />
            </ReferenceField>
            <ReferenceField source="superieur_id" reference="members" label="Destinataire (Supérieur)">
                <TextField source="nom" />
            </ReferenceField>
            <TransferButton />
        </SimpleShowLayout>
    </Show>
);

// Création d'un message
export const MessageCreate = () => {
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [superieurId, setSuperieurId] = useState(null);

    // Récupérer le supérieur automatiquement
    useState(() => {
        dataProvider.getOne("membres", { id: 1 }) // Remplace 1 par l'ID utilisateur connecté
            .then(({ data }) => setSuperieurId(data.superieur_id))
            .catch(() => notify("Erreur lors de la récupération du supérieur", { type: "error" }));
    }, [dataProvider, notify]);

    return (
        <Create>
            <SimpleForm redirect="list">
                <TextInput source="nom" required />
                <TextInput source="prenom" required />
                <TextInput source="email" required />
                <TextInput source="telephone" required />
                <TextInput source="objet" required />
                <TextInput multiline source="corps" required />
            </SimpleForm>
        </Create>
    );
};