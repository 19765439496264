import { useLogin, useNotify } from "react-admin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./LoginPage.css";

const LoginPage = () => {
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Fonction pour gérer l'authentification classique
  const handleClassicLogin = async (e) => {
    e.preventDefault();
    try {
      await login({ email, password, type: "classic" }); // Envoie "type" pour différencier les authentifications
      setError("");
      notify("Connexion réussie", { type: "success" });
    } catch {
      setError("Identifiant ou mot de passe incorrect");
      notify("Identifiant ou mot de passe incorrect", { type: "error" });
    }
  };

  // Fonction pour rediriger vers Google pour l'authentification sociale
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/login/google`;
  };

  // Fonction pour gérer le callback après l'authentification Google
  const handleGoogleCallback = async () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const token = params.get("token");
    console.log("Token from Google callback:", token); // Log du token récupéré
  
    if (token) {
      try {
        await login({ google_token: token, type: "google" });
        notify("Connexion réussie via Google", { type: "success" });
        navigate("/members");
      } catch (error) {
        console.error("Error during Google login:", error);
        setError("Erreur lors de la connexion avec Google");
        notify("Erreur lors de la connexion avec Google", { type: "error" });
      }
    } else {
      console.error("No token found in Google callback URL.");
      setError("Erreur lors de la récupération du token");
      notify("Erreur lors de la récupération du token", { type: "error" });
    }
  };
  
  
  
  

  // Vérifie si l'utilisateur est dans le callback Google
  if (window.location.pathname === "login/google/callbackoffice") {
    handleGoogleCallback();
    return null; // Empêche le rendu du formulaire pendant le traitement du callback
  }

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleClassicLogin}>
        <h2>Connexion Classique</h2>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <button type="submit">Se connecter</button>
        {error && <p className="notify-message">{error}</p>}
      </form>

      <div className="social-login">
        <h2>Ou</h2>
        <button className="google-login" onClick={handleGoogleLogin}>
          Se connecter avec Google
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
